<template>
  <div>
    <b-row class="content-header">
      <b-col class="content-header-left mb-2" cols="12" md="12">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Edit Lead
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in breadcrumbs"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <validation-observer ref="leadForm" #default="{ invalid }">
      <b-row>
        <b-col cols="6">
          <b-card title="Contact Details">
            <div class="form-fields">
              <b-row>
                <b-col cols="12" md="6"
                  ><b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Company Name"
                      rules="required"
                    >
                      <label for="client-name">Company Name</label>
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather-icon
                            icon="BriefcaseIcon"
                            class="cursor-pointer"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="lead.company"
                          id="client-name"
                          placeholder="Enter company name"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group></b-col
                >
                <b-col cols="12" md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <label for="client-name">Contact Email</label>
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather-icon
                            icon="MailIcon"
                            class="cursor-pointer"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="lead.email"
                          id="client-name"
                          placeholder="Enter email address"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Designation"
                      rules="required"
                    >
                      <label for="client-name">Designation</label>

                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather-icon icon="AlignLeftIcon"></feather-icon>
                        </b-input-group-prepend>
                        <b-form-select
                          v-model="lead.designation"
                          :options="designations"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0]
                          ? "Select the designation of the contact person"
                          : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <label for="client-name">Contact Name</label>
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather-icon
                            icon="UserIcon"
                            class="cursor-pointer"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="lead.name"
                          id="client-name"
                          placeholder="Enter full name"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Contact Number"
                      rules="required"
                    >
                      <label for="client-name">Contact Number</label>
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          +91
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="lead.phone"
                          id="client-name"
                          placeholder="Enter city"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <label for="client-name">City</label>
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather-icon
                            icon="MapPinIcon"
                            class="cursor-pointer"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="lead.city"
                          id="client-name"
                          placeholder="Enter city"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Service"
                  rules=""
                >
                  <label for="client-name">Service(s)</label>
                  <v-select
                    label="name"
                    placeholder="Add service(s)"
                    v-model="newServices"
                    multiple
                    :options="services"
                  />
                  <small class="text-danger">{{
                    errors[0]
                      ? "Please select atleast one service from the list"
                      : ""
                  }}</small>
                </validation-provider>
              </b-form-group>

              <div>
                <label>Selected Services</label>
                <div class="service-list">
                  <b-badge
                    class="mr-1"
                    variant="light-primary"
                    v-for="service in lead.services"
                    :key="service._id"
                    >{{ service.name }}

                    <b-button
                      @click="removeService(service._id, service.name)"
                      variant="light"
                      size="sm"
                      class="ml-1 p-0"
                    >
                      <feather-icon icon="XIcon"></feather-icon>
                    </b-button>
                  </b-badge>
                </div>
              </div>

              <b-row>
                <b-col cols="6" class="mx-auto">
                  <b-button
                    @click="updateLead"
                    :disabled="isBusy"
                    class="py-1 mt-2"
                    block
                    variant="primary"
                  >
                    <b-spinner v-if="isBusy" small class="mr-1" />
                    Save Changes
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card title="Task Details">
            <div class="form-fields">
              <b-row>
                <b-col cols="4">
                  <b-form-group>
                    <label for="client-name">Due Date</label>
                    <b-input-group>
                      <b-form-datepicker
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: '2-digit',
                        }"
                        id="datepicker-placeholder"
                        v-model="lead.dueDate"
                        placeholder="Due date"
                        local="en"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Budget"
                      rules="required"
                    >
                      <label for="client-name">Project Budget</label>
                      <b-input-group>
                        <b-form-input
                          v-model="lead.budget"
                          id="client-name"
                          placeholder="Enter task title"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Task Title"
                      rules="required"
                    >
                      <label for="client-name"
                        >Project Cost

                        <b-badge
                          v-if="lead.costDetails.approved"
                          variant="success"
                          >Approved</b-badge
                        >
                      </label>
                      <b-input-group>
                        <b-form-input
                          v-model="lead.costDetails.cost"
                          id="client-name"
                          placeholder="Enter the project cost"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-checkbox
                    v-model="lead.costDetails.approved"
                    checked="true"
                    name="check-button"
                    switch
                    inline
                  >
                    Approved
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12"
                  ><b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Task Title"
                      rules="required"
                    >
                      <label for="client-name">Task Title</label>
                      <b-input-group>
                        <b-form-input
                          v-model="lead.task.title"
                          id="client-name"
                          placeholder="Enter task title"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group></b-col
                >
                <b-col cols="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Task Description"
                      rules="required"
                    >
                      <label for="client-name">Task Description</label>
                      <b-input-group>
                        <b-form-textarea
                          v-model="lead.task.description"
                          id="textarea"
                          placeholder="Enter task description..."
                          rows="10"
                        ></b-form-textarea>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BButton,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BFormSelect,
  BSpinner,
  BFormTextarea,
  BCard,
  BBadge,
  BFormDatepicker,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormCheckbox,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BButton,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BFormSelect,
    BSpinner,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormTextarea,
    BBadge,
    BFormDatepicker,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormCheckbox,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Dashboard",
          active: false,
          to: "/",
        },
        {
          text: "Leads",
          active: false,
          to: "/leads",
        },
      ],
      lead: {
        costDetails: {
          budget: 0,
          approved: false,
        },
        task: {
          title: "",
          description: "",
        },
      },
      required,
      isBusy: false,
      email,
      designations: [],
      services: [],
      newServices: [],
    };
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    getLead() {
      let leadID = this.$route.params.leadID;
      let token = this.$getUserToken();

      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/leads/${leadID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.$set(this, "lead", response.data);
          const lead = response.data;
          this.breadcrumbs.push(
            {
              text: lead.company,
              active: false,
              to: `/leads/${lead._id}`,
            },
            {
              text: "Edit",
              active: true,
              to: `/leads/${lead._id}/edit`,
            }
          );
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    delay(time) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, time);
      });
    },
    updateLead() {
      this.$refs.leadForm.validate().then((success) => {
        if (success) {
          this.$set(this, "isBusy", true);
          let leadID = this.$route.params.leadID;
          if (!this.lead || !leadID) {
            return;
          }

          let lead = JSON.parse(JSON.stringify(this.lead));
          let newServices = this.newServices;
          if (newServices.length) {
            for (let i = 0; i < newServices.length; i++) {
              const newService = newServices[i];
              lead.services.push({
                _id: newService._id,
                name: newService.name,
              });
            }
          }
          axios
            .put(
              `${process.env.VUE_APP_SERVER_URL}/leads/${leadID}/update`,
              lead,
              {
                headers: {
                  Authorization: `Bearer ${this.$getUserToken()}`,
                },
              }
            )
            .then(async (response) => {
              this.$set(this, "isBusy", false);
              this.showToast(
                "Updated",
                "The lead has been updated successfully!",
                "success"
              );
              await this.delay(1000);
              window.location.reload();
            })
            .catch((error) => {
              console.log(error.response);
              this.$set(this, "isBusy", false);
              this.showToast(
                "Error",
                "Unable to update the lead! Please try again later.",
                "danger"
              );
            });
        }
      });
    },
    getDesignations() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/designation`, {
          headers: {
            Authorization: `Bearer ${this.$getUserToken()}`,
          },
        })
        .then((response) => {
          let designations = response.data.designations;
          let designationDropDown = [
            { text: "Designation of the contact person", value: null },
          ];

          for (let i = 0; i < designations.length; i++) {
            const designation = designations[i];
            designationDropDown.push(designation.name);
          }

          this.designations = designationDropDown;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getServices() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/services`, {
          headers: {
            Authorization: `Bearer ${this.$getUserToken()}`,
          },
        })
        .then((response) => {
          let currentServices = {};
          let leadServices = this.lead.services;
          for (let i = 0; i < leadServices.length; i++) {
            const leadService = leadServices[i];
            currentServices[leadService._id] = true;
          }

          this.services = response.data.services.filter((service) => {
            return !currentServices[service._id];
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    removeService(serviceID, serviceName) {
      let leadID = this.$route.params.leadID;
      if (!leadID) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to remove ${serviceName} from the list of services.`,
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "danger",
            okTitle: "Remove",
            cancelTitle: "No",
            cancelVariant: "outline-success",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === false) {
            return;
          }

          axios
            .put(
              `${process.env.VUE_APP_SERVER_URL}/leads/${leadID}/update/remove-service/${serviceID}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${this.$getUserToken()}`,
                },
              }
            )
            .then(async () => {
              this.showToast(
                "Removed",
                "The service has been removed successfully!",
                "success"
              );
              await this.delay(1000);
              window.location.reload();
            })
            .catch((error) => {
              console.log(error.response);
              this.showToast(
                "Error",
                "Cannot remove the service, as it already has fulfilled payments.",
                "danger"
              );
            });
        });
    },
  },
  mounted() {
    this.getLead();
    this.getDesignations();
    this.getServices();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
