var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0"},[_vm._v(" Edit Lead ")]),_c('div',{staticClass:"breadcrumb-wrapper"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"to":"/"}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"HomeIcon","size":"16"}})],1),_vm._l((_vm.breadcrumbs),function(item){return _c('b-breadcrumb-item',{key:item.text,attrs:{"active":item.active,"to":item.to}},[_vm._v(" "+_vm._s(item.text)+" ")])})],2)],1)])],1)],1)],1),_c('validation-observer',{ref:"leadForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{attrs:{"title":"Contact Details"}},[_c('div',{staticClass:"form-fields"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-name"}},[_vm._v("Company Name")]),_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":"client-name","placeholder":"Enter company name"},model:{value:(_vm.lead.company),callback:function ($$v) {_vm.$set(_vm.lead, "company", $$v)},expression:"lead.company"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-name"}},[_vm._v("Contact Email")]),_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"client-name","placeholder":"Enter email address"},model:{value:(_vm.lead.email),callback:function ($$v) {_vm.$set(_vm.lead, "email", $$v)},expression:"lead.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Designation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-name"}},[_vm._v("Designation")]),_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"AlignLeftIcon"}})],1),_c('b-form-select',{attrs:{"options":_vm.designations},model:{value:(_vm.lead.designation),callback:function ($$v) {_vm.$set(_vm.lead, "designation", $$v)},expression:"lead.designation"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Select the designation of the contact person" : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-name"}},[_vm._v("Contact Name")]),_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"client-name","placeholder":"Enter full name"},model:{value:(_vm.lead.name),callback:function ($$v) {_vm.$set(_vm.lead, "name", $$v)},expression:"lead.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Contact Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-name"}},[_vm._v("Contact Number")]),_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" +91 ")]),_c('b-form-input',{attrs:{"id":"client-name","placeholder":"Enter city"},model:{value:(_vm.lead.phone),callback:function ($$v) {_vm.$set(_vm.lead, "phone", $$v)},expression:"lead.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-name"}},[_vm._v("City")]),_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MapPinIcon"}})],1),_c('b-form-input',{attrs:{"id":"client-name","placeholder":"Enter city"},model:{value:(_vm.lead.city),callback:function ($$v) {_vm.$set(_vm.lead, "city", $$v)},expression:"lead.city"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Service","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-name"}},[_vm._v("Service(s)")]),_c('v-select',{attrs:{"label":"name","placeholder":"Add service(s)","multiple":"","options":_vm.services},model:{value:(_vm.newServices),callback:function ($$v) {_vm.newServices=$$v},expression:"newServices"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Please select atleast one service from the list" : ""))])]}}],null,true)})],1),_c('div',[_c('label',[_vm._v("Selected Services")]),_c('div',{staticClass:"service-list"},_vm._l((_vm.lead.services),function(service){return _c('b-badge',{key:service._id,staticClass:"mr-1",attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(service.name)+" "),_c('b-button',{staticClass:"ml-1 p-0",attrs:{"variant":"light","size":"sm"},on:{"click":function($event){return _vm.removeService(service._id, service.name)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)}),1)]),_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"cols":"6"}},[_c('b-button',{staticClass:"py-1 mt-2",attrs:{"disabled":_vm.isBusy,"block":"","variant":"primary"},on:{"click":_vm.updateLead}},[(_vm.isBusy)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" Save Changes ")],1)],1)],1)],1)])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{attrs:{"title":"Task Details"}},[_c('div',{staticClass:"form-fields"},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"client-name"}},[_vm._v("Due Date")]),_c('b-input-group',[_c('b-form-datepicker',{attrs:{"date-format-options":{
                        year: 'numeric',
                        month: 'numeric',
                        day: '2-digit',
                      },"id":"datepicker-placeholder","placeholder":"Due date","local":"en"},model:{value:(_vm.lead.dueDate),callback:function ($$v) {_vm.$set(_vm.lead, "dueDate", $$v)},expression:"lead.dueDate"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Budget","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-name"}},[_vm._v("Project Budget")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"client-name","placeholder":"Enter task title"},model:{value:(_vm.lead.budget),callback:function ($$v) {_vm.$set(_vm.lead, "budget", $$v)},expression:"lead.budget"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Task Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-name"}},[_vm._v("Project Cost "),(_vm.lead.costDetails.approved)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Approved")]):_vm._e()],1),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"client-name","placeholder":"Enter the project cost"},model:{value:(_vm.lead.costDetails.cost),callback:function ($$v) {_vm.$set(_vm.lead.costDetails, "cost", $$v)},expression:"lead.costDetails.cost"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.lead.costDetails.approved),callback:function ($$v) {_vm.$set(_vm.lead.costDetails, "approved", $$v)},expression:"lead.costDetails.approved"}},[_vm._v(" Approved ")])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Task Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-name"}},[_vm._v("Task Title")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"client-name","placeholder":"Enter task title"},model:{value:(_vm.lead.task.title),callback:function ($$v) {_vm.$set(_vm.lead.task, "title", $$v)},expression:"lead.task.title"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Task Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('label',{attrs:{"for":"client-name"}},[_vm._v("Task Description")]),_c('b-input-group',[_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Enter task description...","rows":"10"},model:{value:(_vm.lead.task.description),callback:function ($$v) {_vm.$set(_vm.lead.task, "description", $$v)},expression:"lead.task.description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }